import React, { useState } from "react";
import Logo from "./elorca.ico"; 
import "./PopupModal.css"; 

const PopupModal = ({ onClose, message }) => {
  const [popupContent, setPopupContent] = useState({
    title: "Try It for free ! ",
    description: "Experience the full potential! Sign up now for exclusive features, handcrafted to amplify your journey with us. Don't settle for less."
  });

  const [showSignUpForm, setShowSignUpForm] = useState(false); 
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSignUpClick = () => {
    if (!email) {
      setError("Email is required");
      return;
    }
    setPopupContent({
      title: "YYYaaayyyy !!!",
      description: "Thank you for signing up! Enjoy exclusive features and more."
    });
    setTimeout(() => {
      window.location.replace("https://www.elorca.com/login/");
    }, 500);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handleYesClick = () => {
    setShowSignUpForm(true); 
  };

  const handleNoClick = () => {
    setShowSignUpForm(false);
    onClose(); 
  };
  

  return (
    <div className="popup-container">
      <div className="popup-content">
        <img src={Logo} alt="Logo" className="popup-logo" />
        <div className="popup-text">
          <span className="popup-close" onClick={onClose}>×</span>
          <p className="popup-title">{popupContent.title}</p>
          <p className="popup-description">{popupContent.description}</p>
          <p className="popup-description">WANT TO</p>


<p className="popup-desc" style={{ marginTop: "-20px" }}>Save The Results ?</p>
<p className="popup-desc" style={{ marginTop: "-20px" }}>Next Level ?</p>
<p className="popup-desc" style={{ marginTop: "-20px" }}>Play More Games ?</p>
          {!showSignUpForm && (
            <>
              <p className="popup-description">{message}</p>
              <div className="popup-buttons">
                <button className="popup-button" onClick={handleYesClick}>Yes</button>
                <button className="popup-button" onClick={handleNoClick}>No</button>
              </div>
            </>
          )}
          {showSignUpForm && (
            <>
              <input
                type="text"
                className="form-control"
                placeholder="Enter email address"
                value={email}
                onChange={handleEmailChange}
                required
              />
              <button
                className="btn"
                type="button"
                onClick={handleSignUpClick}
              >
                Sign Up
              </button>
            </>
          )}
        </div>
        {error && <p className="error-text">{error}</p>}
      </div>
    </div>
  );
};

export default PopupModal;
