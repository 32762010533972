import React, { useState } from "react";
import Logo from "../resources/images/elorca-logo.png";
import CopyToClipboard from "react-copy-to-clipboard";

function Navbar() {
  const [copyText, setCopyText] = useState("Copy to Clipboard");

  const handleCopy = () => {
    setCopyText("Copied!");
    setTimeout(() => {
      setCopyText("Copy to Clipboard");
    }, 1000);
  };

  const handleTryItForFreeClick = () => {
    window.location.href = "https://www.elorca.com"; 
  };

  return (
    <nav className="navbar navbar-expand-lg" style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
      <div className="container">
        <a className="navbar-brand" href="#">
          <img src={Logo} alt="logo" width="60px" height="60px" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mb-2 mb-lg-0 ms-auto">
            <li className="nav-item">
              {/* <a className="nav-link active ms-5" href="#">
                Market Place
              </a> */}
            </li>
            <li className="nav-item">
              {/* <a className="nav-link active ms-5" href="#">
                Login
              </a> */}
            </li>
            <li className="nav-item">
              <button
                className="btn ms-5"
                style={{ borderRadius: "3px", backgroundColor: "rgb(252, 169, 3)" }}
                onClick={handleTryItForFreeClick} // Handle navigation onClick
              >
                Try It For Free!
              </button>
            </li>
            <li>
              {/* <CopyToClipboard text='<iframe src="http://localhost:3000/cointoss" width="100%" height="600px"></iframe>'>
                <button
                  className="btn btn-secondary ms-5"
                  style={{ minWidth: "170px" }}
                  onClick={handleCopy}
                >
                  {copyText}
                </button>
              </CopyToClipboard> */}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
