import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import MemoryGame from "./components/MemoryGame";


import '../src/components/Congratulations.css';


function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/memory-game" element={<MemoryGame />} />
      </Routes>
   
    
    </div>
  );
}

export default App;
