import React, { useEffect, useRef, useState } from "react";
import que_icon from "../resources/images/que_icon.svg";
import img1 from "../resources/images/img-1.png";
import img2 from "../resources/images/img-2.png";
import img3 from "../resources/images/img-3.png";
import img4 from "../resources/images/img-4.png";
import img5 from "../resources/images/img-5.png";
import img6 from "../resources/images/img-6.png";
import img7 from "../resources/images/img-7.png";
import img8 from "../resources/images/img-8.png";
import Swal from "sweetalert2";
import PopupModal from "./PopupModal";
import Confetti from "react-confetti";

function MemoryGame() {
  const [array, setArray] = useState([]);
  const [matched, setMatched] = useState(0);
  const [disableDeck, setDisabledDeck] = useState(false);
  const [cardOne, setCardOne] = useState(null);
  const [cardTwo, setCardTwo] = useState(null);
  const [flips, setFlips] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModalOne, setShowModalOne] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const cardsRef = useRef(null);

  const shuffleCard = () => {
    setMatched(0);
    setDisabledDeck(false);
    setCardOne(null);
    setCardTwo(null);

    let arr = [
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      img7,
      img8,
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      img7,
      img8,
    ];
    arr.sort(() => (Math.random() > 0.5 ? 1 : -1));
    setArray(arr);
  };

  function flipCard({ target: clickedCard }) {
    if (clickedCard.classList.length < 2) {
      if (cardOne !== clickedCard && !disableDeck) {
        let flip = flips;
        flip += 1;
        setFlips(flip);
        clickedCard.classList.add("flip");
        if (!cardOne) {
          return setCardOne(clickedCard);
        }
        setCardTwo(clickedCard);
        setDisabledDeck(true);
      }
    }
  }

  useEffect(() => {
    if (flips === 10) {
      setShowModal(true);
    }
  }, [flips]);

  useEffect(() => {
    if (cardTwo !== null) {
      matchCards();
    }
  });

  useEffect(() => {
    if (matched === 8) {
      setTimeout(() => {
        setShowConfetti(true);
        Swal.fire({
          title: flips,
          text: "Congratulations!!! Wanna Play the next level?",
          icon: "success",
          confirmButtonText: "YES",
        }).then((result) => {
          if (result.isConfirmed) {
            setShowModalOne(true);
            setShowConfetti(false);
          }
        });
        cardsRef.current.childNodes.forEach((element) => {
          element.classList.remove("flip");
        });

        shuffleCard();
      }, 500);
    }
  }, [matched]);

  function matchCards() {
    if (
      cardOne.querySelector(".back-view img").src ===
      cardTwo.querySelector(".back-view img").src
    ) {
      let match = matched;
      match += 1;
      setMatched(match);
      setCardOne(null);
      setCardTwo(null);
      return setDisabledDeck(false);
    }
    setTimeout(() => {
      cardOne.classList.add("shake");
      cardTwo.classList.add("shake");
    }, 400);

    setTimeout(() => {
      cardOne.classList.remove("shake", "flip");
      cardTwo.classList.remove("shake", "flip");
      setCardOne(null);
      setCardTwo(null);
      return setDisabledDeck(false);
    }, 1200);
  }

  const closeModal = () => {
    setFlips(0);
    setShowModal(false);
    setShowModalOne(false);
  };

  const refresh = () => {
    cardsRef.current.childNodes.forEach((element) => {
      element.classList.remove("flip");
    });
    shuffleCard();
    setFlips(0);
  };

  useEffect(() => {
    shuffleCard();
  }, []);

  return (
    <div className="container-wrapper d-flex flex-column">
      {showConfetti && <Confetti />}
      <div className="text-center">
        <h3 className="h2 fw-bold my-3 text-white">Memory Game</h3>
      </div>
      <div className="wrapper">
        <ul className="cards-cust" ref={cardsRef}>
          {array.length !== 0 &&
            array.map((img, i) => {
              return (
                <li key={i} className="card-cust" onClick={(e) => flipCard(e)}>
                  <div className="view front-view">
                    <img src={que_icon} alt="icon" />
                  </div>
                  <div className="view back-view">
                    <img src={img} alt="card-img" />
                  </div>
                </li>
              );
            })}
        </ul>
        <div className="details">
          <p className="flips">
            Flips:
            <span>
              <b>{flips}</b>
            </span>
          </p>
          <button onClick={() => refresh()}>Refresh</button>
        </div>
      </div>
      {showModal && (
        <PopupModal
          onClose={() => setShowModal(false)}
          message=""
        />
      )}
      {showModalOne && (
        <PopupModal
          onClose={() => setShowModalOne(false)}
          message="Would you like to sign up?"
        />
      )}
    </div>
  );
}

export default MemoryGame;
